import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { GoogleUser } from '../../app/model/GoogleUser'; 
import { Config } from '../../app/model/Config'; 
import { User } from '../../app/model/User'; 
import { Order } from '../../app/model/Order'; 
import { TypeOfOrder } from '../../app/model/TypeOfOrder'; 
import { AngularFireDatabase, AngularFireAction } from 'angularfire2/database';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
/* import { Router} from '@angular/router';
import { ToastController } from '@ionic/angular'; 
import { Product } from '../model/Product';
import { FirebaseApp } from 'angularfire2'; */
//
//import { NativePageTransitions, NativeTransitionOptions } from '@ionic-native/native-page-transitions';
//import { CartService } from '../../services/cart.service';
 
 
 
import { Observable, Subscription, BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Stock } from '../model/Stock';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  items$: Observable<AngularFireAction<firebase.database.DataSnapshot>[]>;
  size$: BehaviorSubject<string|null>;
  s$: BehaviorSubject<string|null>;
  shipping$: Observable<{}[]>;
  testUser$: Observable<any>;
  config:Config;
  sortOrder = 'ticker';
  headers: Headers = new Headers();
  constructor( public db: AngularFireDatabase, private authService: AuthService) {
  
   }
  ngOnInit() {
    this.headers.append('Accept', 'application/json');
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Access-Control-Allow-Origin', '*');
}

  get(T:string, K:string){
    return this.db.list(T, ref => ref.orderByChild('UserId').equalTo(K)).valueChanges();
  }
  getProduct(T:string, K:string, O:string): any {
    return this.db.list(T, ref => ref.orderByChild(O).equalTo(K)).valueChanges();
  }

/*   getClubSales():Observable<SalesView> {
    let options = new RequestOptions({ headers: this.headers  });
    return this.http.get(this.baseUrl +'/api/service/imports',options).map((response: Response) => <SalesView>response.json()).finally(() => {
  });
}  */
  
  getSingle(T:string, K:string){
    return this.db.object(T+"/" + K);
  }
  getSingle2(T:string){
    return this.db.object(T);
  }
  getObject(T:string, UserId: string){
      let snapshot$ = this.db.list(T, ref => ref.orderByChild("UserId").equalTo(UserId));
    return snapshot$.snapshotChanges()
    .pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }
  getObjectNew(T:string){
    let snapshot$ = this.db.list(T, ref => ref.orderByChild("UserId").equalTo(this.authService.getCurrentUser().UserId));
   return snapshot$.snapshotChanges()
  .pipe(
    map(changes => 
      changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
    )
  );
  }
  getObject2(T:string, sortOrder:string){
    let snapshot$ = this.db.list(T, ref => ref.orderByChild(sortOrder) && ref.orderByChild("status").equalTo('active'));
   return snapshot$.snapshotChanges()
  .pipe(
    map(changes => 
      changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
    )
  );
  }
  getObject3(T:string, sortOrder:string){
    let snapshot$ = this.db.list(T, ref => ref.orderByChild(sortOrder) && ref.orderByChild("UserId"));
    return snapshot$.snapshotChanges()
      .pipe(
        map(changes => 
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }
  save(T:string, record:object){
   return this.db.object(T+"/"+record[0].key).update(record[0]);
  }

  saveObject(object:string, stock:Stock){
    this.db.list('/'+object+'/').push(stock)
     
  }
 
  saveOrder(order:Order){
    this.db.list('/orders/').push(order)
     
  }
  removeRecord(T:string, key:string){
    this.db.object(T+"/"+key).remove();
  }
  //TODO: need to explore switchMap
  get2(T:string, K:string):Observable<any> {
  this.size$ = new BehaviorSubject(null);
  return this.items$ = this.size$.pipe(
    switchMap(UserId => this.db.list(T, ref => UserId ? ref.orderByChild('UserId').equalTo('66qe5nYKBaM3D84ETxZJoXzZ3K93') : ref
      ).snapshotChanges() 
    )
  );
}

findObj(data:any, code:String){
  return data.filter(
      function(data:any){
        return data.ProdStatus == code
      }
  );
}
findObjTicker(data:any, code:String){
  return data.filter(
      function(data:any){
        return data.status == code
      }
  );
}
findKey(data:any, code:number){
  return data.filter(
      function(data:any){
        data.orderNo++
        return data.record === code
      }
  );
}
findUser(data:any, code:String){
  return data.filter(
      function(data:any){
        return data.UserId === code
      }
  );
}
findOrders(data:any, code:number, userId:string){
  return data.filter(
      function(data:any){
        return data.OrderType === code && data.UserId === userId
      }
  );
}
findWishListOrders(data:any, code:string, userId:string){
  return data.filter(
      function(data:any){
        return data.Cart[0].Product_id === code && data.UserId === userId
      }
  );
}
findShippingMethod(data:any, code:String, rule:boolean){
  return data.filter(
      function(data:any){
        return data.Default === rule  && data.UserId === code
      }
  );
}
findPromoCode(data:any, code:String, rule:string){
  return data.filter(
      function(data:any){
        return data.Rule === rule && data.UserId === code && data.Active == true
      }
  );
}
getShipping(){
    this.s$ = new BehaviorSubject(null);
   return  this.s$.pipe(
      switchMap(s => 
        this.db.list('/shippingRules').snapshotChanges() 
      )
    );
  }
  getTicker(){
    this.s$ = new BehaviorSubject(null);
   return  this.s$.pipe(
      switchMap(s => 
        this.db.list('/ticker').snapshotChanges() 
      )
    );
  }
} 
