 
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from '../../app/environments/environments';
//import { ApiAiClient } from 'api-ai-javascript';
import { ApiAiClient } from 'api-ai-javascript/es6/ApiAiClient'
import {BehaviorSubject, Observable, Subject, Subscriber, of} from 'rxjs';
 
import * as JsEncryptModule from 'jsencrypt';
const CART_KEY = 'cartItems';


@Injectable()
export class CartService {
  private behave = new BehaviorSubject<Object>(null);
  private itemsInCartSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private itemsInCartSubjectTotal: BehaviorSubject<number[]> = new BehaviorSubject([]);
  readonly token = environment.dialogflow.angularBot;
  //readonly client = new ApiAiClient({ accessToken: this.token });
  conversation = new BehaviorSubject<any[]>([]);
  private itemsInCart: any[] = [];
  private _prop: string;
  
  constructor(public storage: Storage) {

    var encrypt = new JsEncryptModule.JSEncrypt();
    console.log(this.token);
    this.getCartItems().then(result => {
      
      if(result != null ){
          this.itemsInCartSubject.next([...this.itemsInCart, result.length]);
          this.behave.next(result.length);
      }else{
        this.itemsInCartSubject.next([...this.itemsInCart, 0]);
        this.behave.next('0');
      }
     // this.itemsInCartSubject.subscribe(_ => this.itemsInCart = _);
    });  
   
  }
  public get prop() : string
    {
        return this._prop;
    }
  public set prop(str:string) 
    {
        this._prop = str;
    }
  setBehaviorView(behave: Object) { 
      this.behave.next(behave); 
  } 
  getBehaviorView(): Observable<any> { 
      return  this.behave.asObservable(); 
  }
  public getItems(): Observable<any[]> {
    return this.itemsInCartSubject.asObservable();
  }
  addToCart(product) {
     debugger
    return this.getCartItems().then(result => {

      if (result) {
       
        if (!this.containsObject(product, result)) {
            result.push(product);
            this.behave.next(result.length);
            return this.storage.set(this.prop, result);
        } else {
            let index = result.findIndex(x => x.Product_id == product.Product_id);
            let prevQuantity = parseInt(result[index].Count);
            product.Count = (prevQuantity + product.Count);
            product.TotalPrice = (parseFloat(product.UnitPrice) * product.Count);
           // product.totalPrice =currentPrice;
            result.splice(index, 1);
            result.push(product);
           
            this.itemsInCartSubject.next([...this.itemsInCart, result.length]);
            this.itemsInCartSubjectTotal.next([result.length])
            //this.behave.next(result.length);
            debugger
            return this.storage.set(this.prop, result);
        }

      } else {
       
        this.behave.next(1);
        debugger
        return this.storage.set(this.prop, [product]);
      }
    })
  }

  
   
  updateCart(product) {
    return this.getCartItems().then(result => {
      if (result) {
        
        if (!this.containsObject(product, result)) {
            result.push(product);
            return this.storage.set(this.prop, result);
        } else {
          let index = result.findIndex(x => x.Product_id == product.Product_id);
          let prevQuantity = parseInt(result[index].Count);
          product.Count =   product.Count ;
          product.TotalPrice = (parseFloat(product.UnitPrice) * product.Count)
         // product.totalPrice =currentPrice;
          result.splice(index, 1);
          result.push(product);

            return this.storage.set(this.prop, result);
        }

      } else {
        return this.storage.set(this.prop, [product]);
      }
    })
  }
  removeFromCart(product, index:number) {
    return this.getCartItems().then(result => {
      if (result) {
        let index = result.findIndex(x => x.Product_id == product.Product_id);
        var productIndex = result.indexOf(product);
        result.splice(index,1);
        this.behave.next(result.length);
        return this.storage.set(this.prop, result);
      }
    })
  }

  removeAllCartItems() {
      this.storage.remove(this.prop); 
      this.behave.next('0');
  }

  containsObject(obj, list): boolean {
    if (!list.length || obj == null) {
      return false;
    }

   
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].Product_id == obj.Product_id) {
        return true;
      }
    }
    return false;
  }
 
  getCartItems() {
    //let total = this.storage.get(this.prop) 
  // let len = this.storage.get(this.prop);
   
   return this.storage.get(this.prop);
  }
  async getCartTotal() {
   
     this.storage.get(CART_KEY).then(result => {
      if (result) {
       this.behave.next(result.length);
       return result.length ;
      }else{
        return 0;
      }
    })
  }
  public submit(q:string):void {
		
		const length = q.length+1;
		const answer = `"${q}" contains ${length} symbols`;
		setTimeout(
		() => this.update(answer),
		1000
		);
		
	
 }
 update(msg: string) {
  this.conversation.next([msg]);
}

}
