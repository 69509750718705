 
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Observable, BehaviorSubject } from 'rxjs';
import { GoogleUser } from '../../app/model/GoogleUser'; 
import { User } from '../../app/model/User'; 
import { AngularFireDatabase } from 'angularfire2/database';
import { Router} from '@angular/router';
import { ToastController,  AlertController } from '@ionic/angular'; 

@Injectable()

export class AuthService {

  public authState: any = null;
  public user: Observable<firebase.User>;
   
  public appUser:GoogleUser;
  private userSubject = new BehaviorSubject(null);
  public user$: Observable<any> = this.userSubject.asObservable();

  constructor(private firebaseAuth: AngularFireAuth,
              public dbFB: AngularFireDatabase, public router:Router,
              public toastCtrl: ToastController) {
   
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  if(currentUser != null){
    this.userSubject.next(currentUser.DisplayName);
     console.log("Auth Service " + currentUser.UserId);
    this.firebaseAuth.authState.subscribe((auth) => {
     this.authState = auth;
  }); 
 this.user = firebaseAuth.authState;
 }
}
testAuth(){
   return this.firebaseAuth.authState;
  }
 /*  getUser(): Promise<any> {
    return this.firebaseAuth.authState.pipe(first()).toPromise();
} */
getCurrentUser():GoogleUser
{
  return JSON.parse(localStorage.getItem('currentUser'))
}
  
authenticated(): Observable<firebase.User>{
  
    return this.user;
}


doGoogleLogin(){
  return new Promise<any>((resolve, reject) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    this.firebaseAuth.auth
    .signInWithPopup(provider)
    .then(res => {
      resolve(res);
      if ((localStorage.getItem("currentUser") === null)) {
        localStorage.setItem('currentUser', JSON.stringify({ Email: res.user.email, DisplayName: res.user.displayName, Photo:res.user.photoURL, UserId:res.user.uid }));
      } 
      
      this.router.navigateByUrl('/menu/home');
    })
  })
}
forgotPassword(email: string){
 return this.firebaseAuth
    .auth
    .sendPasswordResetEmail(email)
}
loginOld(email: string, password: string){
  return new Promise<any>((resolve, reject) => {
    let provider = new firebase.auth.GoogleAuthProvider();
    let toa = this.toastCtrl.create({ message: "login error",
    duration: 2000});
    provider.addScope('profile');
    provider.addScope('email');
    
    this.firebaseAuth.auth
    .signInWithEmailAndPassword(email, password)
    .then(res => {
     
      
      this.userSubject.next(res.user.displayName);
      if ((localStorage.getItem("currentUser") === null)) {
        localStorage.setItem('currentUser', JSON.stringify({ Email: res.user.email, DisplayName: res.user.displayName, Photo:res.user.photoURL, UserId:res.user.uid }));
      } 
      this.router.navigateByUrl('/menu/home');
    }).catch(function(err) {
      return(err);
      //this.presentToast(err.message)
  })
  })
}

login(email: string, password: string):any {
  var error = "";
   
  return   this.firebaseAuth
    .auth
    .signInWithEmailAndPassword(email, password).then(function(value) {
     
      if ((localStorage.getItem("currentUser") === null)) {
              localStorage.setItem('currentUser', JSON.stringify({ Email: value.user.email, DisplayName: value.user.displayName, Photo:value.user.photoURL, UserId:value.user.uid }));
            } 
         return error;
  }).catch(function(error) {
  
     return error;
  }) 
}
signup(email: string, password: string):any {
 
 return this.firebaseAuth
    .auth
    .createUserWithEmailAndPassword(email, password)
    .then(value => {
    
      let googleUser:GoogleUser;
      const newContent = <User>({
        Email:value.user.email,
        UserId:value.user.uid,
        Photo: value.user.photoURL,
        DisplayName:value.user.displayName == "" ? " ": value.user.displayName ,
     });
     
      let tempUser = this.dbFB.list('/users');
  
      tempUser.push(newContent);

      if ((localStorage.getItem("currentUser") === null)) {
        localStorage.setItem('currentUser', JSON.stringify({ Email: value.user.email, DisplayName: value.user.displayName, Photo:value.user.photoURL, UserId:value.user.uid }));
     } 
     this.router.navigate(['/menu/home'])
    })
    .catch(err => {
     // return err.message;
      this.presentToast(err.message)
      console.log('Something went wrong:',err.message);
    });    
}
async presentToast(name:string) {
  const toast = await this.toastCtrl.create({
    message: name,
    duration: 4000,
    showCloseButton: true,
    color: 'danger',
  });
  toast.present();
}

pushUser(user:User) {
  let tempUser = this.dbFB.list('/users');
  
  tempUser.push(user);

  if ((localStorage.getItem("currentUser") === null)) {
    localStorage.setItem('currentUser', JSON.stringify({ Email:  user.Email, DisplayName: user.DisplayName, Photoa:user.PhotoURL, UserId:user.UserId }));
 } 
 
}
logout() {
  this.firebaseAuth
    .auth
    .signOut() 
   .then((res) => this.router.navigate(['/login']));
}
signInWithGoogle() {
  return this.firebaseAuth.auth.signInWithPopup(
    new firebase.auth.GoogleAuthProvider()
  )
}
signInWithTwitter() {
  return this.firebaseAuth.auth.signInWithPopup(
    new firebase.auth.TwitterAuthProvider()
  )
}
signInWithFacebook() {
  return this.firebaseAuth.auth.signInWithPopup(
    new firebase.auth.FacebookAuthProvider()
  )
}

  /* signup(email: string, password: string):any {
   
    this.firebaseAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
      .then(value => {
      
        let googleUser:GoogleUser;
        const newContent = <User>({
          Email:value.user.email,
          UserId:value.user.uid,
          Photo: value.user.photoURL,
          DisplayName:value.user.displayName == "" ? " ": value.user.displayName ,
          Address:" ",
          City:" ",
          State:" ",
          Zip:" ",
          ShippingAddress:" ",
          ShippingCity:" ",
          ShippingState:" ",
          ShippingZip:" ",
          Website:" "

       });
       
        let tempUser = this.dbFB.list('/users');
    
        tempUser.push(newContent);

        if ((localStorage.getItem("currentUser") === null)) {
          localStorage.setItem('currentUser', JSON.stringify({ Email: value.user.email, DisplayName: value.user.displayName, Photo:value.user.photoURL, UserId:value.user.uid }));
       } 
       
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      });    
  }

 
 
   */

}
