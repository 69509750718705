import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../app/environments/environments';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { CartService } from '../app/services/cart.service';
import { FcmService } from '../app/services/fcm.service';
import { AuthService } from '../app/services/auth.service';
import { AuthGuardService} from '../app/services/auth-guard.service';
import { IonicStorageModule } from '@ionic/storage';
import { DataService } from '../app/services/data.service';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireMessaging } from 'angularfire2/messaging';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FCM } from '@ionic-native/fcm/ngx';
import { OAuthModule } from  "angular-oauth2-oidc";
import { ServiceWorkerModule } from '@angular/service-worker';
import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule } from '@angular/forms';
 
 
 
 

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, AngularFireModule.initializeApp(environment.firebase), CKEditorModule, FormsModule,
    OAuthModule.forRoot(),
    AngularFirestoreModule,
    HttpClientModule,
    AngularFireDatabaseModule,  
    AngularFireFunctionsModule,             
    AngularFireAuthModule,
    AngularFireMessagingModule, 
    IonicStorageModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CartService, 
    AuthService,
    FcmService,
    DataService,
    AuthGuardService,
   
    FCM,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
